$c-primary: #FF0000;
$c-secandary: #FF0000;
$dots: false;
$version: 3.1;
$flow-v2-bg-header-overrided: #F9FAFA;
$heading-color-overrided: #343741;
$body-color-overrided: #343741;
$progress-bar-overided: #fff;
$progress-bar-incomplete-overided: #000;
$base-font-family: 'Rubik', sans-serif;
$font-family-heading: 'Rubik', sans-serif;

      @font-face {
        font-family: 'Rubik';
        src: url('/assets-mobile/fonts/Rubik-Bold.ttf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/assets-mobile/fonts/Rubik-BoldItalic.ttf');
        font-weight: 600;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/assets-mobile/fonts/Rubik-Medium.ttf');
        font-weight: 500;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/assets-mobile/fonts/Rubik-MediumItalic.ttf');
        font-weight: 500;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/assets-mobile/fonts/Rubik-Regular.ttf');
        font-weight: 400;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/assets-mobile/fonts/Rubik-Italic.ttf');
        font-weight: 400;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/assets-mobile/fonts/Rubik-Light.ttf');
        font-weight: 300;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/assets-mobile/fonts/Rubik-LightItalic.ttf');
        font-weight: 300;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Oswald';
        src: url('/assets-mobile/fonts/Oswald-Regular.ttf');
        font-weight: 400;
        font-style: normal;
      }

    

            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  padding-top: 15px;
  margin-top: -15px;
  text-align: center;

  .spinner {
    img {
      animation: rotate 1s linear infinite;
      width: 70px;
    }
    svg {
      animation: rotate 1s linear infinite;
      width: 70px;
    }
    .svg {
      fill: black;
    }
  }

  .heading {
    font-family: var(--heading-font-family) !important;
    color: var(--heading-text-color) !important;
    margin-top: 2rem;
    margin-bottom: 1rem;
    text-align: center;
    font-weight: 600;
    font-size: var(--heading-font-size) !important;
    line-height: 31px;
    letter-spacing: -0.3px;
  }

  .content {
    //margin-top: 20px;
    font-size: 14px;
    text-align: center;
    img {
      display: block;
      margin: 0 auto 10px;
      max-width: 200px;
    }
  }
}
