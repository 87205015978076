$c-primary: #FF0000;
$c-secandary: #FF0000;
$dots: false;
$version: 3.1;
$flow-v2-bg-header-overrided: #F9FAFA;
$heading-color-overrided: #343741;
$body-color-overrided: #343741;
$progress-bar-overided: #fff;
$progress-bar-incomplete-overided: #000;
$base-font-family: 'Rubik', sans-serif;
$font-family-heading: 'Rubik', sans-serif;

      @font-face {
        font-family: 'Rubik';
        src: url('/assets-mobile/fonts/Rubik-Bold.ttf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/assets-mobile/fonts/Rubik-BoldItalic.ttf');
        font-weight: 600;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/assets-mobile/fonts/Rubik-Medium.ttf');
        font-weight: 500;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/assets-mobile/fonts/Rubik-MediumItalic.ttf');
        font-weight: 500;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/assets-mobile/fonts/Rubik-Regular.ttf');
        font-weight: 400;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/assets-mobile/fonts/Rubik-Italic.ttf');
        font-weight: 400;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/assets-mobile/fonts/Rubik-Light.ttf');
        font-weight: 300;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/assets-mobile/fonts/Rubik-LightItalic.ttf');
        font-weight: 300;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Oswald';
        src: url('/assets-mobile/fonts/Oswald-Regular.ttf');
        font-weight: 400;
        font-style: normal;
      }

    

            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: stretch;
  font-size: 1rem;
  line-height: 1.25;
  font-family: var(--base-font-family);
  &.fill_view_port {
    height: 100%;
  }

  .heading {
    font-family: var(--heading-font-family) !important;
    color: var(--heading-text-color) !important;
    margin-bottom: 1.5rem; //24
    text-align: left;
    font-weight: 600;
    font-size: var(--heading-font-size) !important;
  }

  .description {
    text-align: left;
  }

  .option_list {
    margin-top: 1.5rem; //24
    margin-bottom: 1.5rem; //24

    .option_item {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: stretch;
      height: 2.8125rem; //45
      margin-bottom: 0.6875rem; //11
      cursor: pointer;
      border: 1px solid #ccc;
      border-radius: 10px;
      &.checked {
        background: #f4f4f4;
        border-radius: 10px;
      }

      .check_mark_wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 3.3125rem; //53
        height: 2.8125rem; //45

        img {
          width: 1.375rem; //22
          height: auto;
        }
      }

      .option_title {
        flex: 1;
      }
    }
  }

  .animation-container {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    width: 100%;
    flex: 1;
    overflow: hidden;
    background-color: var(--page-background-color);
  }
}
